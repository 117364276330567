import React from 'react';

// types
import type { WCmsPageImageView } from '@zola/svc-web-api-ts-client';

// utils
import { getWebsitePlaceholderAsset } from 'components/manage/EditWebsite/common/HeroCustomizer/utils';

// components
import { PageAnimationWrapper } from 'components/publicWebsiteV2/common/PageAnimationWrapper/PageAnimationWrapper';
import { getAnimationPropsBannerHero } from 'components/publicWebsiteV2/common/OverlayAnimation/pageAnimationUtils';
import { useAnimationContext } from 'components/manage/EditWebsite/EditWebsiteAddEffects/components/AnimationContext/AnimationContext';
import LegacyHero from './Hero';

export type MultiPageHeroSingleImageNarrowProps = {
  images?: WCmsPageImageView[];
  isDesktop?: boolean; // Note: this prop takes into account device size AND being in the preview
  isSamplePage?: boolean;
  onDownCaretClick?: () => void;
  title?: string;
};

const MultiPageHeroSingleImageNarrow: React.FC<MultiPageHeroSingleImageNarrowProps> = ({
  images,
  isDesktop,
  isSamplePage,
  onDownCaretClick,
  title,
}) => {
  const { pageAnimation } = useAnimationContext();

  return (
    <div data-testid="MultiPageHeroSingleImageNarrow">
      <PageAnimationWrapper
        animationProps={{
          isHero: true,
          direction: { wipeDirection: 'down', panDirection: 'right' },
          ...getAnimationPropsBannerHero(pageAnimation),
        }}
      >
        <LegacyHero
          title={title}
          images={images}
          onDownCaretClick={onDownCaretClick}
          placeholderImg={getWebsitePlaceholderAsset('SINGLE_IMAGE_MULTI_PAGE', true, 1)}
          isSamplePage={isSamplePage}
          isDesktop={isDesktop}
        />
      </PageAnimationWrapper>
    </div>
  );
};

export default MultiPageHeroSingleImageNarrow;
